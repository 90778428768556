<template>
  <!--员工编辑-->
  <div>
    <div v-if="record == false">
      <div class="title">
        <div class="title_t">
          <span>员工编辑</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="150px">

        <el-card shadow="never">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">基本信息</span>
          </div>

          <el-row :gutter="15" style="margin-top: 10px;">
            <el-col :span="12">
              <el-form-item label="姓名" prop="realname">
                <el-input clearable style="" v-model="form.realname" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="账号" prop="account">
                <el-input clearable style="" v-model="form.account" placeholder="请输入账号"></el-input>
              </el-form-item>
            </el-col>
            <!--<el-col :span="12">-->
            <!--  <el-form-item label="所属机构">-->
            <!--    <span>默认为当前登录用户所在的顶级公司</span>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="手机号" prop="phone">
                <el-input clearable style="" v-model="form.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="部门">
                <!--<el-select-->
                <!--    clearable-->
                <!--    v-model="form.authentication_status"-->
                <!--    placeholder="请选择"-->
                <!--    class="ele-fluid">-->
                <!--  <el-option label="全部" value="1"/>-->
                <!--  <el-option label="待认证" value="2"/>-->
                <!--  <el-option label="已认证" value="3"/>-->
                <!--</el-select>-->
                <div class="sel">
                  <!--multiple 多选设置 用即为true-->
                  <select_tree style="height: 36px !important;"  v-model="form.dept_id" filterable :data="list"/>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="登录密码" prop="password">
                <el-input clearable style="" show-password v-model="form.password" placeholder="请输入登录密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="角色">
                <el-select
                    clearable
                    multiple
                    v-model="form.roles_id"
                    placeholder="请选择"
                    class="ele-fluid">
                  <el-option
                      v-for="(item,index) in role"
                      :label="item.name"
                      :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="12">-->
          <!--    <div class="bujustyle">-->
          <!--      <el-form-item label="薪酬规则">-->
          <!--        <el-select-->
          <!--            @change="change"-->
          <!--            clearable-->
          <!--            v-model="form.remuneration_id"-->
          <!--            placeholder="请选择"-->
          <!--            class="ele-fluid">-->
          <!--          <el-option-->
          <!--              v-for="(item) in remuneration"-->
          <!--              :label="item.name"-->
          <!--              :value="item.id"/>-->
          <!--        </el-select>-->
          <!--        <el-button style="background: #EBE6F7!important;border-color: #7958C7!important;color: #7958C7!important;margin-left: 15px;" @click="addrecord()">创建薪酬</el-button>-->
          <!--      </el-form-item>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--</el-row>-->
        </el-card>

        <!--<el-card shadow="never">-->
        <!--  <div style="display: flex;align-items: center;">-->
        <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--    <span style="font-size: 20px;font-weight: 700;color: #202033;">岗位设置</span>-->
        <!--  </div>-->
        <!--  <el-row :gutter="15" style="margin-top: 10px;">-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="坐席位" prop="seat_cards">-->
        <!--        <el-input clearable style="" v-model="form.seat_cards" placeholder="请输入坐席位"></el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="外呼方式" prop="outbound_mode">-->
        <!--        <el-radio-group v-model="form.outbound_mode">-->
        <!--          <el-radio v-for="(item) in dict.type.Outboundmode" :label="parseInt(item.value)">{{item.label}}</el-radio>-->
        <!--        </el-radio-group>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15" style="margin-top: 10px;">-->
        <!--    <el-col :span="12">-->
        <!--      <div class="bujustyle">-->
        <!--        <el-form-item label="薪酬规则">-->
        <!--          <el-select-->
        <!--              clearable-->
        <!--              v-model="form.salary_rules"-->
        <!--              placeholder="请选择"-->
        <!--              class="ele-fluid">-->
        <!--            <el-option-->
        <!--                v-for="(item,index) in remuneration"-->
        <!--                :label="item.name"-->
        <!--                :value="item.id"/>-->
        <!--          </el-select>-->
        <!--          <el-button style="background: #EBE6F7!important;border-color: #7958C7!important;color: #7958C7!important;margin-left: 15px;" @click="addrecord()">创建薪酬</el-button>-->
        <!--        </el-form-item>-->
        <!--      </div>-->
        <!--    </el-col>-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="接单项目" prop="salary_rules">-->
        <!--        &lt;!&ndash;<el-checkbox-group v-model="form.checkList">&ndash;&gt;-->
        <!--        &lt;!&ndash;  &ndash;&gt;-->
        <!--        &lt;!&ndash;</el-checkbox-group>&ndash;&gt;-->
        <!--        <el-checkbox v-for="(item,index) in ServicesAvailable" :key="index" :label="item.label"></el-checkbox>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="月度营业额目标值">-->
        <!--        <el-input placeholder="请输入内容" v-model="form.target_value">-->
        <!--          <template slot="append">%</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->

        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="月度营业额提成比例">-->
        <!--        <el-input placeholder="请输入内容" v-model="form.commission_royalties">-->
        <!--          <template slot="append">%</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->

        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="12">-->
        <!--      <el-form-item label="月度利润提成比例">-->
        <!--        <el-input placeholder="请输入内容" v-model="form.profit_royalties">-->
        <!--          <template slot="append">%</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->

        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-card>-->

        <div style="margin-top: 30px;text-align: center;">
          <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
          <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')">提交</el-button>
        </div>


      </el-form>
    </div>

    <!--薪酬记录-->
    <div v-if="record == true">
      <!--薪酬记录组件-->
      <Pay @showPay="ShowPay"/>
    </div>

  </div>
</template>

<script>
import {Addstaff, Editstaff, getget_format, role_select, staff} from "@/api/humanResources";

// 下拉树
import select_tree from "@/components/select_tree/index.vue";
import {getget_services_available, getemolument_list, some_facilitator_remuneration} from "@/api/yunli";

// 员工编辑 薪酬管理
import Pay from "@/views/yunli/pay/index.vue";

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  // 组件生效
  components: {
    select_tree,
    Pay
  },
  // Data数据
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        // password:[
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        // ],
        // seat_cards:[
        //   { required: true, message: '请输入坐席位', trigger: 'blur' },
        // ],
        // outbound_mode: [
        //   { required: true, message: '请选择外呼方式', trigger: 'change' },
        // ],
      },
      isUpdate:false,

      //薪酬记录模块
      record:false,

      list: [],

      checkList:[],
      ServicesAvailable:[],

      //薪酬
      remuneration:[],

      //角色
      role:[]

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    if (this.data) {
      // this.form = Object.assign({}, this.data);
      this.isUpdate = true;
      // 获取详情
      staff(this.data.id).then(res => {
        console.log(res)
        this.form = res.data;
      })
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 获取其部门
    this.getList();

    // 获取服务项目
    // this.getFuwuxiangmu();

    //调用获取薪酬
    this.getremuneration();

    //获取角色
    this.getRole();

  },
  // 方法集合
  methods: {
    // 获取部门
    getList(){
      getget_format().then(res => {
        this.list = res.data;
      })
    },

    //获取角色下拉
    getRole(){
      role_select().then(res => {
        // console.log(res)
        this.role = res.data;
      })
    },

    //获取薪酬
    getremuneration(){
      some_facilitator_remuneration().then(res => {
        console.log(res);
        this.remuneration = res.data;
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    // 返回上级页面
    returnPage(){
      this.$emit('showEdit',false) ;
    },

    //点击创建薪酬
    addrecord(){
      // this.record = true;
      this.$router.push('/humanResources/WorkerSalaryManagement')
    },
    //薪酬记录的返回按钮
    recordreturnPage(){
      this.record = false;
    },

    //获取薪酬规则选择的值
    change(value){
      console.log(value)
      this.form.remuneration_id = value;
    },

    // 保存
    save(formName){
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是创建还是编辑
          if(this.isUpdate === false){
            let data = {
              realname: this.form.realname,
              account: this.form.account,
              phone: this.form.phone,
              password: this.form.password,
              remuneration_id: this.form.remuneration_id,
              dept_id: this.form.dept_id,
              roles_id: this.form.roles_id,
            }
            //创建
            Addstaff(data).then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showEdit',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else {
            let data = {
              realname: this.form.realname,
              account: this.form.account,
              phone: this.form.phone,
              password: this.form.password,
              remuneration_id: this.form.remuneration_id,
              dept_id: this.form.dept_id,
              roles_id: this.form.roles_id,
            }
            //编辑
            Editstaff(this.form.id,data).then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showEdit',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },

    // 薪酬记录接口
    ShowPay(value){
      this.record = value;
    },


  },
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

</style>
